$body-bg: $blue-grey-50;

$primary: map-get($primary-palette, 500);
$primary-light: map-get($primary-palette, 100);
$primary-lighter: map-get($primary-palette, 50);
$secondary: map-get($secondary-palette, 500);
$white: #fff;
$black: #000;
$dark: $grey-500;
$danger-palette: (
  50: $red-50,
  100: $red-100,
  200: $red-200,
  300: $red-300,
  400: $red-400,
  500: $red-500,
  600: $red-600,
  700: $red-700,
  800: $red-800,
  900: $red-900,
);

$warning-palette: (
  50: $orange-50,
  100: $orange-100,
  200: $orange-200,
  300: $orange-300,
  400: $orange-400,
  500: $orange-500,
  600: $orange-600,
  700: $orange-700,
  800: $orange-800,
  900: $orange-900,
);

$success-palette: (
  50: $green-50,
  100: $green-100,
  200: $green-200,
  300: $green-300,
  400: $green-400,
  500: $green-500,
  600: $green-600,
  700: $green-700,
  800: $green-800,
  900: $green-900,
);

$info-palette: (
  50: $blue-50,
  100: $blue-100,
  200: $blue-200,
  300: $blue-300,
  400: $blue-400,
  500: $blue-500,
  600: $blue-600,
  700: $blue-700,
  800: $blue-800,
  900: $blue-900,
);

$light-palette: (
  50: $grey-50,
  100: $grey-100,
  200: $grey-200,
  300: $grey-300,
  400: $grey-400,
  500: $grey-500,
  600: $grey-600,
  700: $grey-700,
  800: $grey-800,
  900: $grey-900,
);

$theme-colors-palette: (
  "primary": $primary-palette,
  "secondary": $secondary-palette,
  "success": $success-palette,
  "info": $info-palette,
  "warning": $warning-palette,
  "danger": $danger-palette,
  "light": $light-palette,
  "dark": $light-palette,
) !default;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
);

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $grey-900 !default;
$yiq-text-light: $white !default;

$border-color: $grey-300;

$font-size-base: 1rem; //16px
$font-sizes: (
  //font-size-xxl - 22px
  "1": $font-size-base * 1.375,
  //font-size-xl - 20px
  "2": $font-size-base * 1.25,
  //font-size-lg - 18px
  "3": $font-size-base * 1.125,
  //font-size-md - 16px
  "4": $font-size-base * 0.875,
  //font-size-sm - 14px
  "5": $font-size-base * 0.875,
  //font-size-xs - 12px
  "6": $font-size-base * 0.75,
  //font-size-xxs - 10px
  "7": $font-size-base * 0.688
);
$font-family-sans-serif: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI",
  "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

$box-shadow-sm: shadow($black, "sm");
$box-shadow: shadow($black, "md");
$box-shadow-lg: shadow($black, "lg");
$box-shadow-xl: shadow($black, "xl");
$box-shadow-inset-sm: shadow($black, "sm", true);
$box-shadow-inset: shadow($black, "md", true);
$box-shadow-inset-lg: shadow($black, "lg", true);
$box-shadow-inset-xl: shadow($black, "xl", true);

$font-weight-lighter: 100 !default;
$font-weight-text: 400 !default;

$font-weight-bold: 500 !default;
$font-weight-bolder: 700 !default;

//Inputs
$input-color: $grey-600;

//Component active
$component-active-bg: $primary;

//Tables
$table-head-bg: $blue-grey-50;
$table-bg: white;
$table-head-font-size: map-get($font-sizes, "6");
// $table-cell-padding: map-get($spacers,2);
// $table-head-color: $yiq-text-dark ;
$table-striped-order: even;
$table-striped-order-color: lighten($blue-grey-50, 5%);

//Buttons
$btn-font-size-xs: map-get($font-sizes, "6");
$btn-font-size-xm: map-get($font-sizes, "5");

//Dropdown
$dropdown-link-hover-bg: $primary;

//Pagination
$pagination-color: $primary;
$pagination-border-color: transparent;
$pagination-hover-color: $primary;
$pagination-hover-bg: map-get($primary-palette, 50);
$pagination-bg-transparent: transparent;

//Breadcrumb
$breadcrumb-font-size: $font-size-base;
$breadcrumb-color: $yiq-text-dark;
$breadcrumb-active-color: $yiq-text-dark;

//Bootstrap validation
$form-invalid-color: #fbeaec;
$form-invalid-border-color: #d44c59;
