/* #region Animation curve*/
$deceleration-curve: cubic-bezier(0, 0, 0.2, 1);
$standard-curve: cubic-bezier(0.4, 0, 0.2, 1);
$acceleration-curve: cubic-bezier(0.4, 0, 1, 1);
$sharp-curve: cubic-bezier(0.4, 0, 0.6, 1);
/* #endregion */

//font size
$global-font-size: 13px;

/* #region Spacing */
$padding-xs: map-get($spacers, 1);
$padding-sm: map-get($spacers, 2);
$padding-md: map-get($spacers, 3);
$padding-lg: map-get($spacers, 4);
$padding-xl: map-get($spacers, 5);
$margin-xs: map-get($spacers, 1);
$margin-sm: map-get($spacers, 2);
$margin-md: map-get($spacers, 3);
$margin-lg: map-get($spacers, 4);
$margin-xl: map-get($spacers, 5);
/* #endregion */

//body
$body-line-height: 1.5;

//header
$header-height: 50px;
$header-bg: #{$grey-50};
$header-color: #{$primary};

//color
$gray-dark: $gray-700;

//palette
$topbar-palette: makeColorPalette(#dbdbdb);

//navbar
$navbar-brand-bg: #{$white};
$navbar-fs: 0.83rem;
$navbar-fw: 500;
$navbar-link-hover-bg: #{$gray-200};
$navbar-link-hover-color: #{$grey-900};
$navbar-brand-shadow: #{$box-shadow-lg};
$navbar-bg: #{$white};

//page
$page-container-background: white;

//sidebar
$sidebar-width: 72px;
$sidebar-bg: #{$secondary};
$sidebar-shadow: #{$box-shadow-lg};
$sidebar-link-text-size: map-get($font-sizes, "7");
$sidebar-link-color: $color-contrast-light;
$sibebar-link-border-width: 1px 0;
$sibebar-link-border-color: $sidebar-bg;
$sidebar-link-active: map-get($secondary-palette, 900);
$sidebar-link-active-border: $sidebar-link-active;
$sidebar-link-hover: map-get($secondary-palette, 400);
$sidebar-link-hover-border: $sidebar-link-active;
$sidebar-icon-size: $font-size-base * 1.75;

//subheader
$sub-header-border: $border-color;
$sub-header-bg: white;

//form
$form-label-color: #{$grey-900};
$form-label-font-size: var(--bs-fs-6);
$form-title-margin-bottom: 0.75rem;
$custom-select-color: svg-color($input-color);
$form-group-title-font-size: $font-size-base;
$form-group-title-color: $secondary;
$form-input-readonly-bg: #f0f0f0;

//dropdown
$dropdown-border-radius: 0.25rem;
$dropdown-item-hover-bg: $grey-200;
$dropdown-item-active-bg: $grey-300;
$dropdown-item-hover-color: #141414;
$dropdown-item-fw: 400;
$dropdown-item-fs: map-get($font-sizes, "6");
$dropdown-item-padding: 0.5rem;
$dropdown-item-disabled: #858585;
$dropdown-item-bg: #{$white};

//button
$bs-btn-font-size: map-get($font-sizes, "6");

//search-tag
$search-tag-prepend: $primary !default;
$search-tag-border: $border-color !default;
$search-tag-close-hover: $gray-100;
$search-tag-padding: $padding-xs/2 $padding-xs;

//card
$card-max-width: 1250px;
$bs-card-bg: $white;

//State breadcrumb
$state-breadcrumb-border-width: 0.063rem;
$state-breadcrumb-border-color: $white;
$topbar-bg: map-get($topbar-palette, 100);

// Partner data card
$card-img-size: 50px; //square of ...px
$partner-card-height: 120px;

//Tabs
$nav-tabs-color: $gray-500;
$nav-tabs-hover-color: $gray-dark;
$tab-grid-min-height: 350px;

//badge
$badge-light-info-color: #03a9f4;
$badge-light-success-color: #4caf50;
$badge-light-secondary-color: #ffd800;
$badge-light-primary-color: #03787c;
$badge-light-warning-color: #ff9800;
$badge-light-danger-color: #f44336;
$badge-light-light-color: #9e9e9e;
$badge-light-dark-color: #fafafa;
$badge-light-brown-color: #534434;

$badge-light-info-bg-color: #e1f5fe;
$badge-light-success-bg-color: #e8f5e9;
$badge-light-secondary-bg-color: #fffae0;
$badge-light-primary-bg-color: #e1efef;
$badge-light-warning-bg-color: #fff3e0;
$badge-light-danger-bg-color: #ffebee;
$badge-light-light-bg-color: #f5f5f5;
$badge-light-dark-bg-color: #bdbdbd;
$badge-light-brown-bg-color: #d8cbc4;

//smart actions
$smart-link-width: 120px;
$smart-link-hover-bg: #{map-get($light-palette, 100)};

//textarea height size
$textarea-height-size-sm: 5em;
$textarea-height-size-m: 10em;
$textarea-height-size-l: 20em;
$textarea-height-size-xl: 25em;

@mixin root-variables {
  --bs-spacer-0: #{map-get($spacers, 0)};
  --bs-spacer-1: #{map-get($spacers, 1)};
  --bs-spacer-2: #{map-get($spacers, 2)};
  --bs-spacer-3: #{map-get($spacers, 3)};
  --bs-spacer-4: #{map-get($spacers, 4)};
  --bs-spacer-5: #{map-get($spacers, 5)};

  @each $size, $value in $font-sizes {
    --bs-fs-#{$size}: #{$value};
  }
  --font-family: #{$font-family-sans-serif};

  --bs-dropdown-border-radius: #{$dropdown-border-radius};

  //body
  --bs-body-line-height: #{$body-line-height};

  //breakpoints
  @each $size, $breakpoint in $grid-breakpoints {
    --bs-breakpoint-#{$size}: #{$breakpoint};
  }

  --acceleration-curve: #{$acceleration-curve};

  //shadow
  --box-shadow-sm: #{$box-shadow-sm};
  --box-shadow: #{$box-shadow};

  //font size
  --global-font-size: #{$global-font-size};
  --font-size-base: #{$font-size-base};
  --h2-font-size: #{$h2-font-size};
  --btn-font-size-xs: #{$btn-font-size-xs};
  --font-size-small: #{$small-font-size};
  --font-size-sm: #{$font-size-sm};
  --font-size-xxl: #{map-get($font-sizes, "1")};
  --font-size-xl: #{map-get($font-sizes, "2")};
  --font-size-lg: #{map-get($font-sizes, "3")};
  --font-size-md: #{map-get($font-sizes, "4")};
  --font-size-sm: #{map-get($font-sizes, "5")};
  --font-size-xs: #{map-get($font-sizes, "6")};
  --font-size-xxs: #{map-get($font-sizes, "7")};

  //font weight
  --bs-font-weight-semibold: #{$font-weight-semibold};
  --font-weight-bold: #{$font-weight-bold};
  --font-weight-bolder: #{$font-weight-bolder};
  --font-weight-lighter: #{$font-weight-lighter};
  --font-weight-text: #{$font-weight-text};

  //color
  --primary: #{$primary};
  --primary-lighter: #{$primary-lighter};
  --primary-light: #{$primary-light};
  --border-color: #{$border-color};
  --btn-border-danger-color: #{map-get($danger-palette, 400)};
  --breadcrumb-color: #{$breadcrumb-color};
  --grey-300: #{$grey-300};
  --grey-500: #{$grey-500};
  --grey-600: #{$grey-600};
  --grey-700: #{$grey-700};
  --grey-900: #{$grey-900};
  --info-600: #{map-get($info-palette, 500)};
  --success-500: #{map-get($success-palette, 500)};
  --success-600: #{map-get($success-palette, 600)};
  --danger-500: #{map-get($danger-palette, 500)};
  --danger-600: #{map-get($danger-palette, 600)};
  --warning-600: #{map-get($warning-palette, 600)};

  //loader
  --loader-bg: #{$page-container-background};
  --loader-spinner-color: #{$primary};

  //padding
  --padding-xs: #{$padding-xs};
  --padding-sm: #{$padding-sm};
  --padding-md: #{$padding-md};
  --padding-lg: #{$padding-lg};

  //margin
  --margin-xs: #{$margin-xs};
  --margin-sm: #{$margin-sm};
  --margin-md: #{$margin-md};
  --margin-lg: #{$margin-lg};
  --margin-xl: #{$margin-xl};

  //form
  --form-label-color: #{$form-label-color};
  --form-label-font-size: #{$form-label-font-size};
  --form-title-margin-bottom: #{$form-title-margin-bottom};
  --form-title-color: #{$grey-600};
  --custom-select-color: #{$custom-select-color};
  --form-group-title-font-size: #{$form-group-title-font-size};
  --form-group-title-color: #{$form-group-title-color};
  --form-input-readonly-bg: #{$form-input-readonly-bg};

  //label
  --label-color: #{$grey-900};
  --label-empty-color: #{$grey-400};

  //card
  --card-bg: #{$bs-card-bg};
  --card-max-width: #{$card-max-width};
  --card-right-min-width: calc(#{$card-img-size} + #{$padding-sm});

  //header
  --header-height: #{$header-height};
  --header-bg: #{$header-bg};
  --navbar-brand-bg: #{$navbar-brand-bg};
  --navbar-link-hover-bg: #{$navbar-link-hover-bg};
  --navbar-link-hover-color: #{$navbar-link-hover-color};
  --navbar-brand-shadow: #{$navbar-brand-shadow};
  --navbar-fs: #{$navbar-fs};
  --navbar-fw: #{$navbar-fw};
  --header-color: #{$header-color};
  --navbar-bg: #{$navbar-bg};

  //sidebar
  --sidebar-width: #{$sidebar-width};
  --sidebar-bg: #{$sidebar-bg};
  --sidebar-shadow: #{$sidebar-shadow};
  --sidebar-link-text-size: #{$sidebar-link-text-size};
  --sidebar-link-color: #{$sidebar-link-color};
  --sibebar-link-border-width: #{$sibebar-link-border-width};
  --sibebar-link-border-color: #{$sibebar-link-border-color};
  --sidebar-link-active: #{$sidebar-link-active};
  --sidebar-link-active-border: #{$sidebar-link-active-border};
  --sidebar-link-hover: #{$sidebar-link-hover};
  --sidebar-link-hover-border: #{$sidebar-link-hover-border};
  --sidebar-icon-size: #{$sidebar-icon-size};

  //subheader
  --sub-header-border: #{$sub-header-border};
  --sub-header-bg: #{$sub-header-bg};

  //Dropdown
  --dropdown-link-hover-color: #{$white};
  --dropdown-link-hover-bg: #{$dropdown-link-hover-bg};
  --dropdown-item-hover-bg: #{$dropdown-item-hover-bg};
  --dropdown-item-active-bg: #{$dropdown-item-active-bg};
  --dropdown-item-hover-color: #{$dropdown-item-hover-color};
  --dropdown-item-fw: #{$dropdown-item-fw};
  --dropdown-item-fs: #{$dropdown-item-fs};
  --dropdown-item-padding: #{$dropdown-item-padding};
  --dropdown-item-disabled: #{$dropdown-item-disabled};
  --dropdown-item-bg: #{$dropdown-item-bg};

  //Pagination
  --pagination-color: #{$pagination-color};
  --pagination-border-color: #{$pagination-border-color};
  --pagination-hover-color: #{$pagination-hover-color};
  --pagination-hover-bg: #{$pagination-hover-bg};
  --pagination-font-size: #{map-get($font-sizes, "4")};
  --pagination-bg-transparent: #{$pagination-bg-transparent};

  //Component active
  --component-active-bg: #{$component-active-bg};

  //tables
  --table-striped-order-color: #{$table-striped-order-color};
  --table-hover-bg: #{$primary-lighter};
  --table-head-bg: #{$table-head-bg};
  --table-head-font-size: #{$table-head-font-size};
  --table-row-active-bg: #{$component-active-bg};
  --table-row-font-size: #{map-get($font-sizes, "6")};
  --table-row-font-weight: #{$font-weight-text};

  //Tabs
  --nav-tabs-color: #{$nav-tabs-color};
  --nav-tabs-hover-color: #{$nav-tabs-hover-color};
  --tab-grid-min-height: #{$tab-grid-min-height};

  //comment
  --comment-bg: #{$sub-header-bg};
  --comment-border-color: #{$sub-header-border};
  --comment-page-container-background: #{$page-container-background};
  --comment-avatar-bg: #{$sidebar-bg};

  //buttons
  --btn-font-size: #{$bs-btn-font-size};

  //search-tag
  --search-tag-border: #{$search-tag-border};
  --search-tag-prepend: #{$search-tag-prepend};
  --search-tag-padding: #{$search-tag-padding};
  --search-tag-close-hover: #{$search-tag-close-hover};

  //breadcrumb
  --state-breadcrumb-border-width: #{$state-breadcrumb-border-width};
  --state-breadcrumb-padding: #{$state-breadcrumb-border-width}* 3.1 + #{$padding-sm};
  --state-breadcrumb-border-color: #{$state-breadcrumb-border-color};
  --topbar-bg: #{$topbar-bg};

  //button
  --bs-btn-bg: #{$primary};

  // Partner data card
  --card-img-size: #{$card-img-size};
  --partner-card-height: #{$partner-card-height};

  //badge (tags)
  --badge-tags-font-size: var(--font-size-xs);
  --badge-light-info-color: #{$badge-light-info-color};
  --badge-light-success-color: #{$badge-light-success-color};
  --badge-light-secondary-color: #{$badge-light-secondary-color};
  --badge-light-primary-color: #{$badge-light-primary-color};
  --badge-light-warning-color: #{$badge-light-warning-color};
  --badge-light-danger-color: #{$badge-light-danger-color};
  --badge-light-light-color: #{$badge-light-light-color};
  --badge-light-dark-color: #{$badge-light-dark-color};
  --badge-light-brown-color: #{$badge-light-brown-color};

  --badge-light-info-bg-color: #{$badge-light-info-bg-color};
  --badge-light-success-bg-color: #{$badge-light-success-bg-color};
  --badge-light-secondary-bg-color: #{$badge-light-secondary-bg-color};
  --badge-light-primary-bg-color: #{$badge-light-primary-bg-color};
  --badge-light-warning-bg-color: #{$badge-light-warning-bg-color};
  --badge-light-danger-bg-color: #{$badge-light-danger-bg-color};
  --badge-light-light-bg-color: #{$badge-light-light-bg-color};
  --badge-light-dark-bg-color: #{$badge-light-dark-bg-color};
  --badge-light-brown-bg-color: #{$badge-light-brown-bg-color};

  //validation
  --validation-bg-color: #{$form-invalid-color};
  --validation-border-color: #{$form-invalid-border-color};

  //smart actions
  --smart-link-width: #{$smart-link-width};
  --smart-link-hover-bg: #{$smart-link-hover-bg};

  //textarea height size
  --textarea-height-size-sm: #{$textarea-height-size-sm};
  --textarea-height-size-m: #{$textarea-height-size-m};
  --textarea-height-size-l: #{$textarea-height-size-l};
  --textarea-height-size-xl: #{$textarea-height-size-xl};

  //list group
  --list-group-item-bg: #{$white};

  //modal
  --modal-bg: #{$white};
}
