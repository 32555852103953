.form-group {
  margin-bottom: map-get($spacers, 2);
}
.form-label {
  margin-bottom: 0;
  color: var(--form-label-color);
  font-size: var(--form-label-font-size);
  font-weight: var(--bs-font-weight-semibold);
}
.form-check {
  font-size: var(--form-label-font-size);
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #757575;
  background-color: transparent;
  border: solid transparent;
  //border-width: 1px 0;
}
.form-control {
  box-sizing: border-box;
  border-radius: 0;
  border-color: transparent transparent $border-color;
  outline: none;
  background-clip: border-box;
  background-color: transparent;
  background-image: linear-gradient($component-active-bg, $component-active-bg);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 0 1px;
  box-shadow: none !important;
  //transition: background 0.1s $acceleration-curve;

  &:focus {
    background-color: transparent;
    border-color: transparent;
    outline: none;
    background-size: 110% 2px;
    transition-duration: 0.3s;
  }

  &.ng-invalid {
    //border-bottom-color: $danger;
    //background-image: linear-gradient($danger, $danger);
    background-color: #fff8e1 !important;
  }

  &.is-invalid {
    background-color: var(--validation-bg-color) !important;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom-color: var(--validation-border-color) !important;
    background-image: none !important;
    border-bottom-width: 1px;
    padding: 0.25rem 0.5rem;
  }
  //&:read-only {
  //  background-color: #f0f0f0;
  //  //$ng-select-readonly-background-color: #f0f0f0;
  //}

}
.form-title {
  @extend .card-title, .form-control-plaintext, .py-0;
  font-size: var(--h2-font-size) !important;
  margin-bottom: var(--form-title-margin-bottom);
  color: var(--form-title-color);
  border-radius: 0.2rem; //

  border-top-width: 1px !important;
  border-right-width: 1px !important;
  border-left-width: 1px !important;

}
.form-group-title {
  font-size: var(--form-group-title-font-size);
  //color: var(--form-group-title-color);
  color: var(--header-color) !important;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: var(--global-font-size);
  line-height: 1.5;
  border-radius: 0.2rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: var(--global-font-size);
  line-height: 1.5;
}

select.form-control {
	float: none;
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20fill%3D%22%23757575%22%20stroke%3D%22transparent%22%20stroke-width%3D%220%22%20style%3D%22%22%20d%3D%22M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z%22%20%2F%3E%3C%2Fsvg%3E"),linear-gradient(#03787c,#03787c);
	background-repeat: no-repeat;
	background-clip: border-box;
	background-position: right var(--padding-sm) center, bottom center;
	background-size: var(--font-size-sm) var(--font-size-sm), 0 1px;
	border-color: transparent transparent var(--border-color);
	border-radius: 0;
	outline: none;
	box-shadow: none !important;
	transition: background 0.1s var(--acceleration-curve);
	box-sizing: border-box;
	padding-right: var(--padding-lg);
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

	&:focus {
		background-size: var(--font-size-sm) var(--font-size-sm), 110% 2px;
		border-color: transparent;
		outline: none;
		transition-duration: 0.3s;
	}

	// &.input-validation-error {
	// 	border-bottom-color: $danger;
	// 	background-image: svgIcon(chevron-down, currentColor), linear-gradient( $danger, $danger );
	// }
}

.search-input {
  border-bottom: 1px solid var(--border-color);

  .form-control {
    margin-top: 1px;
    border: transparent;
  }
}
.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 0.5;
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
  opacity: 0.5;
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
  opacity: 0.5;
}
